<template>
  <div class="goprofessionDetail">
    <div class="minw" v-loading="loading">

      <div class="bottom">
        <el-button style="margin:10px 0 0 93%" @click="$router.go(-1)">返回</el-button>
        <div style="text-align: center;font-size: 18px;font-weight: 900;">{{ detailinfo.careerName}}</div>
        <h1 class="title">岗位定义</h1>
        <p class="line"></p>
        <p class="detail">{{ detailinfo.careerDefin || '--' }}</p>
        <h1 class="title">职业介绍</h1>
        <p class="line"></p>
        <p class="detail">{{ detailinfo.careerIntro || '--' }}</p>
        <h1 class="title">职业特点</h1>
        <p class="line"></p>
        <p class="detail">{{ detailinfo.careerChara || '--' }}</p>
        <h1 class="title">发展空间</h1>
        <p class="line"></p>
        <p class="detail">{{ detailinfo.careerSpace || '--' }}</p>
        <h1 class="title">匹配专业</h1>
        <p class="line"></p>
        <p class="detail">
          <span class="detailItem" v-for="(item,index) in careerTreeVoList" :key="index">
            {{ item.careerMajor }}
            <label v-show="index != careerTreeVoList.length-1">、</label>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { queryCareerDetail } from "@/api/professionalinvestigation.js";
export default {
  name: "goprofessionDetail",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      detailinfo: {},
      careerTreeVoList:[],
      loading: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDetails(this.$route.query.id)
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // 使用平滑滚动效果
        });
  },
  mounted() { },
  methods: {
    getDetails(id) {
      const self = this;
      self.loading = true;
      let params = {
        id,
      }
      queryCareerDetail(params).then(res => {
        if (res.code == 200) {
          self.detailinfo = res.data.careerEntity;
          self.careerTreeVoList = res.data.careerTreeVoList;
          self.loading = false;
        }
      })
    }
  },


};
</script>

<style scoped lang="scss">
.goprofessionDetail {
  box-sizing: border-box;
  width: 100%;
  // background-color: #fcfbf9;
  padding: 30px 0 80px 0;
  .top,
  .bottom {
    box-sizing: border-box;
    padding: 35px 30px;
    padding-top: 0px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    background: white;
  }
  .top {
  }
  .bottom {
    // margin-top: 30px;
    .line {
      margin: 3px;
      height: 3px !important;
      width: 35px;
      background: #30C9B2;
      margin-bottom: 10px;
    }
    .title {
      font-weight: 900;
      font-size: 16px;
      margin-top: 10px;
    }

    .detail {
      text-indent: 30px;
      line-height: 27px;
      font-size: 15px;
    }
  }
}
</style>
